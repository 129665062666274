import React from "react";
import App from 'next/app';
import {ApolloProvider} from "@apollo/client";
import {useApollo} from "../utils/apollo";
import {configureAmplify} from "utils/amplify";
import {AuthProvider} from "../contexts/auth/auth.provider";
import '../assets/css/tailwind.css';
import '../assets/css/blockui.css';
import {useRouter} from "next/router";
import * as Sentry from '@sentry/node';
import {Toaster} from "react-hot-toast";

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
    Sentry.init({
        enabled: process.env.NODE_ENV === 'production',
        dsn: process.env.NEXT_PUBLIC_SENTRY_DSN
    });
}

function MyApp({
                   Component,
                   pageProps,
                   deviceType,
                   pathname,
                   hostname,
                   err,
                   viewerCountry
               }) {
    const router = useRouter();
    console.log(pathname)
    if (pathname.includes('/affiliates')) {
        const apolloClient = useApollo(pageProps.apolloState)
        try {
            configureAmplify(null, hostname);
        } catch (e) {
            console.log(e)
        }


        return <div>
            <Toaster containerClassName="toastie-container"
                     toastOptions={{
                         className: 'toastie',
                         style: {
                             borderRadius: '10px',
                             background: '#333',
                             color: '#fff',
                         },
                     }}
            />
            <AuthProvider viewerCountry={viewerCountry}>
                <ApolloProvider client={apolloClient}>
                    <Component {...pageProps} deviceType={deviceType} err={err}/>
                </ApolloProvider>
            </AuthProvider>
        </div>
    }


    return <>
        <Toaster containerClassName="toastie-container"
                 toastOptions={{
                     className: 'toastie',
                     style: {
                         borderRadius: '10px',
                         background: '#333',
                         color: '#fff',
                     },
                 }}
        />
        <Component {...pageProps} deviceType={deviceType} err={err}/>
    </>
}


MyApp.getInitialProps = async appContext => {
    const appProps = await App.getInitialProps(appContext);
    const {req, query} = appContext.ctx;
    const viewerCountry = appContext.ctx.req?.headers['cloudfront-viewer-country'];
    const deviceType = {
        mobile: (appContext.ctx.req?.headers['cloudfront-is-mobile-viewer']) === 'true',
        desktop: (appContext.ctx.req?.headers['cloudfront-is-desktop-viewer']) === 'true',
        tablet: (appContext.ctx.req?.headers['cloudfront-is-tablet-viewer']) === 'true',
    };
    console.log(viewerCountry, appContext.router?.pathname,)
    return {
        ...appProps,
        query,
        deviceType,
        pathname: appContext.router?.pathname,
        hostname: appContext.ctx.req?.headers.host,
        viewerCountry
    };
};


export default MyApp
