import React, {useMemo} from 'react';
import {createAuthLink} from 'aws-appsync-auth-link';
import {createSubscriptionHandshakeLink} from 'aws-appsync-subscription-link';
import fetch from 'isomorphic-unfetch';
import merge from 'deepmerge'
import {ApolloClient, ApolloLink, createHttpLink, HttpLink, InMemoryCache,} from '@apollo/client'
import {Auth} from 'aws-amplify';

let apolloClient = null;


const url = (process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT as any);
const region = (process.env.NEXT_PUBLIC_GRAPHQL_REGION as any);

const auth: any = {
    type: 'AWS_IAM',
    credentials: () => ({
        accessKeyId: process.env.BACKEND_ACCESS_KEY_ID, // public-graphql
        secretAccessKey: process.env.BACKEND_SECRET_ACCESS_KEY,
    })
};

const httpLink = new HttpLink({uri: url, fetch});

// @ts-ignore
const unauthedLink = ApolloLink.from([
    createAuthLink({url, region, auth}) as any,
    createSubscriptionHandshakeLink(url, httpLink as any),
]);

const authedLink = ApolloLink.from([
    createAuthLink({
        url,
        region,
        auth: {
            type: 'AMAZON_COGNITO_USER_POOLS',
            jwtToken: async () => {
                try {
                    return (await Auth.currentSession()).getIdToken().getJwtToken();
                } catch (e) {
                    return null;
                }
            },
        },
    }),
    createSubscriptionHandshakeLink(url, httpLink),
]);


/**
 * Always creates a new apollo client on the server
 * Creates or reuses apollo client in the browser.
 * @param  {Object} initialState
 */
export function initApolloClient(initialState?) {
    return initializeApollo(initialState)
}

function createApolloClient() {
    // Check out https://github.com/zeit/next.js/pull/4611 if you want to use the AWSAppSyncClient
    // Check https://www.loudnoises.us/next-js-two-apollo-clients-two-graphql-data-sources-the-easy-way/ for splitting links
    return new ApolloClient({
        ssrMode: typeof window === 'undefined', // Disables forceFetch on the server (so queries are only run once)
        link: ApolloLink.split(
            operation => operation.getContext().clientName === 'authed', // Routes the query to the proper client
            authedLink,
            unauthedLink
        ),
        cache: new InMemoryCache(),
    });
}


export function initializeApollo(initialState = null) {
    const _apolloClient = apolloClient ?? createApolloClient()

    // If your page has Next.js data fetching methods that use Apollo Client, the initial state
    // gets hydrated here
    if (initialState) {
        // Get existing cache, loaded during client side data fetching
        const existingCache = _apolloClient.extract()
        // Restore the cache using the data passed from getStaticProps/getServerSideProps
        // combined with the existing cached data
        // Merge the existing cache into data passed from getStaticProps/getServerSideProps
        const data = merge(initialState, existingCache)

        _apolloClient.cache.restore(data)
    }
    // For SSG and SSR always create a new Apollo Client
    if (typeof window === 'undefined') return _apolloClient
    // Create the Apollo Client once in the client
    if (!apolloClient) apolloClient = _apolloClient

    return _apolloClient
}

export function useApollo(initialState) {
    const store = useMemo(() => initializeApollo(initialState), [initialState])
    return store
}


const backendLink = ({key, secret}) => ApolloLink.from([
    createAuthLink({
        url,
        region,
        auth: {
            type: 'AWS_IAM',
            credentials: () => ({
                accessKeyId: key,
                secretAccessKey: secret,
            })
        }
    }),
    createSubscriptionHandshakeLink(url, createHttpLink({uri: url, fetch})),
]);


export function createBackendApolloClient({key, secret}) {
    console.log(`createBackendApolloClient`, url, key);
    return new ApolloClient({
        ssrMode: typeof window === 'undefined', // Disables forceFetch on the server (so queries are only run once)
        link: backendLink({key, secret}),
        cache: new InMemoryCache().restore({}),
    });
}


