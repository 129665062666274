import {Amplify} from 'aws-amplify';
import NextStorage from "amplify-auth-next-storage";

const awsconfig: any = process.env.AMPLIFY_CONFIG;

export function configureAmplify(ctx, host) {
    const cookieHost = (ctx?.req.headers['host'] || host).split(":")[0];
    console.log('cookieHost', cookieHost);

    Amplify.configure({
        Auth: {
            identityPoolId: awsconfig.aws_cognito_identity_pool_id,
            region: awsconfig.aws_project_region,
            identityPoolRegion: awsconfig.aws_project_region,
            userPoolId: awsconfig.aws_user_pools_id,
            userPoolWebClientId: awsconfig.aws_user_pools_web_client_id,
            mandatorySignIn: false,
            cookieStorage: {
                domain: cookieHost,
                path: '/',
                expires: 7,
                secure: !(process.env.NODE_ENV === 'development'),
            },
            storage: new NextStorage(ctx, {
                domain: cookieHost,
                expires: 7,
                path: '/',
                secure: !(process.env.NODE_ENV === 'development'),
            }),
            authenticationFlowType: 'USER_SRP_AUTH',
            clientMetadata: {
                service: 'cj-checkout',
                host: cookieHost
            },
            oauth: awsconfig.oauth
        },
        ...awsconfig,
    });
}


export function configureCookielessAmplify(req) {
    let cookieHost = (req.headers['host']).split(":")[0];
    if (cookieHost === "localhost") {
        cookieHost = 'checkoutjoy.local';
    }
    console.log('cookieHost', cookieHost);

    Amplify.configure({
        Auth: {
            identityPoolId: awsconfig.aws_cognito_identity_pool_id,
            region: awsconfig.aws_project_region,
            identityPoolRegion: awsconfig.aws_project_region,
            userPoolId: awsconfig.aws_user_pools_id,
            userPoolWebClientId: awsconfig.aws_user_pools_web_client_id,
            mandatorySignIn: false,
            cookieStorage: {
                domain: cookieHost,
                path: '/',
                expires: 7,
                secure: !(process.env.NODE_ENV === 'development'),
            },
            authenticationFlowType: 'USER_SRP_AUTH',
            clientMetadata: {service: 'cj-frontend'},
            oauth: awsconfig.oauth
        },
        ...awsconfig,
        ssr: true
    });
}
