import React, {createContext, useEffect, useState} from 'react';
import {Auth, Hub} from "aws-amplify";
import {configureAmplify} from "utils/amplify";
import {useRouter} from "next/router";
import moment from "moment-timezone";

export const AuthContext = createContext({} as any);

export async function getLoggedInUser(ctx) {
    configureAmplify(ctx, null);
    try {
        const user = await Auth.currentAuthenticatedUser();
        return user;
    } catch (e) {
        if (ctx) console.log(e);
        return null;
    }
}

export function isAffiliate(user) {
    return user.signInUserSession.accessToken.payload["cognito:groups"]?.includes('Affiliate');
}

export function isAccountOwner(user) {
    return !!user.attributes['custom:company'];
}

async function handleLogin(email, password) {
    return Auth.signIn(email, password)
        .catch(err => {
            let message = err.message;
            if (err.code === 'UserNotConfirmedException') {
                // The error happens if the user didn't finish the confirmation step when signing up
                // In this case you need to resend the code and confirm the user
                // About how to resend the code and confirm the user, please check the signUp part
                message = 'Your email address has not been confirmed.  Please check your ' +
                    'inbox and follow the instructions in the welcome email.';

                console.log(message);
            } else if (err.code === 'PasswordResetRequiredException') {
                // The error happens when the password is reset in the Cognito console
                // In this case you need to call forgotPassword to reset the password
                // Please check the Forgot Password part.
                console.log(err);
            } else if (err.code === 'NotAuthorizedException') {
                // The error happens when the incorrect password is provided
                console.log(err);
            } else if (err.code === 'UserNotFoundException') {
                // The error happens when the supplied username/email does not exist in the Cognito user pool
                console.log(err);
            } else {
                console.log(err);
            }
            throw err;

        });
}


export const AuthProvider = ({children, viewerCountry}) => {
    const router = useRouter();
    const [user, setUser] = useState(null);
    const [company, setCompany] = useState(null);
    const [loading, setLoading] = useState(null);


    useEffect(() => {
        if (user) {
            setCompany(user.attributes['custom:affiliateCompany']);
        }
    }, [user]);

    useEffect(() => {
        initialiseUser().catch(e => console.log('auth init', e));
        Hub.listen("auth", (payloadData) => {
            console.log(payloadData);
            const {payload: {event, data}} = payloadData;
            switch (event) {
                case 'cognitoHostedUI':
                    initialiseUser(true)
                        .then((u) => {
                            console.log(`cognitoHostedUI`, u);
                            if (u.attributes['custom:affiliateCompany']) {
                                router.push('/affiliates/dashboard');
                            }
                        })
                        .catch((e) => console.log(`cognitoHostedUI`, e));
                    break;
            }
        });
    }, []);

    async function initialiseUser(bypassCache = false) {
        return Auth.currentAuthenticatedUser({bypassCache})
            .then((user) => {
                setUser(user);
                return user;
            })
            .catch((e) => {
                console.log(e);
            });
    }


    const login = (email, password) => {
        setLoading(true);
        return handleLogin(email, password)
            .then((user) => {
                setUser(user);
                return user;
            }).finally(() => setLoading(false));
    }

    const resetPassword = (email) => {
        setLoading(true);
        return Auth.forgotPassword(email)
            .finally(() => setLoading(false));
    };
    const newPasswordSubmit = (username, code, newPassword) => {
        setLoading(true);
        return Auth.forgotPasswordSubmit(username, code, newPassword)
            .finally(() => setLoading(false));
    };

    const federatedSignIn = ({provider, customState}) => {
        setLoading(true);
        return Auth.federatedSignIn({provider, customState})
            .finally(() => setLoading(false));
    };

    async function signUp({
                              username,
                              password,
                              firstName,
                              lastName,
                              attributes
                          }) {
        setLoading(true);
        return Auth.signUp({
            username,
            password,
            attributes: {
                email: username,
                given_name: firstName,
                family_name: lastName,
                'custom:signup': JSON.stringify(attributes)
            }
        }).finally(() => setLoading(false));
    }

    async function logout() {
        setLoading(true);
        return Auth.signOut().finally(() => setLoading(false));
    }


    return (
        <AuthContext.Provider value={{
            company,
            login,
            logout,
            signUp,
            user,
            isAuthenticated: !!user,
            federatedSignIn,
            loading,
            resetPassword,
            newPasswordSubmit,
            initialiseUser,
            viewerCountry,
            timeZone: moment.tz.guess(),
        }}>
            {children}
        </AuthContext.Provider>
    );
};
